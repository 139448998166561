<template>
  <div class="page">
    <validation-observer
      ref="registerForm"
      #default="{invalid}"
    >
      <div class="row">
        <div class="col-md-12">
          <b-card>
            <div class="flex-justify-between">
              <b-button
                v-b-modal.modal-import-listing
                variant="outline-secondary"
              >
                Import Listing by MLS
              </b-button>
              <b-button
                variant="outline-primary"
                type="submit"
                :disabled="invalid"
                @click="submit"
              >
                Save
              </b-button>
            </div>
            <b-tabs class="mt-2">
              <b-tab
                title="Listing"
                active
              >
                <div class="row">
                  <div class="col-md-3">
                    <b-card title="Add New Listing">
                      <Listing
                        :data="data"
                        :options="options"
                        @update-data="updateData"
                      />
                    </b-card>
                  </div>
                  <div class="col-md-3">
                    <b-card
                      title="Commercial"
                    >
                      <Commercial
                        :data="data"
                        :options="options"
                        @update-data="updateData"
                      />
                    </b-card>
                  </div>
                  <div class="col-md-3">
                    <b-card title="Area">
                      <Area
                        :data="data"
                        :options="options"
                        update-data="updateData"
                      />
                    </b-card>
                  </div>
                  <div class="col-md-3">
                    <b-card title="Remark">
                      <Remark
                        :data="data"
                        update-data="updateData"
                      />
                    </b-card>
                  </div>
                </div>

              </b-tab>
              <b-tab
                title="Images"
              >
                <Photo
                  :photo-list="getImage"
                  source="exclusive"
                  @select-photo="selectImgaeFromMedia"
                  @delete-photo="deleteImageFromMedia"
                />
              </b-tab>
              <b-tab
                title="Medias"
              >
                <Media
                  :media-data="media"
                  @update-data="updateMediaData"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>

      </div>
    </validation-observer>
    <b-modal
      id="modal-import-listing"
      ref="my-modal"
      title="Enter MLS ID"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :state="mlsState"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="MLS ID"
          label-for="mls-id"
          invalid-feedback="MLS ID is required"
        >
          <b-form-input
            id="mls-id"
            v-model="data.mls_id"
            :state="mlsState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BButton, BTabs, BTab, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Listing from './components/Listing.vue'
import Commercial from './components/Commercial.vue'
import Area from './components/Area.vue'
import Remark from './components/Remark.vue'
import Media from '../components/Media.vue'
import Photo from '../../components/Media/Photo.vue'

export default {
  components: {
    BCard,
    BButton,
    Listing,
    ValidationObserver,
    Commercial,
    Area,
    Remark,
    BTab,
    BTabs,
    BFormGroup,
    BFormInput,
    Photo,
    Media,
  },
  data() {
    return {
      required,
      mlsState: null,
      options: {},
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 300,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
      },
      data: {
        status: 'Active',
        property_type: 'Commercial',
        mls_id: '',
        list_date: '',
        asking_price: '',
        class: 'CM_1',
        area: '',
        subarea: '',
        address_unit: '',
        address_number: '',
        address_street: '',
        address_street_designation_id: '',
        address_direction: '',
        city: '',
        province: '',
        postal_code: '',
        remark: '',
        virtual_tour: '',
        imageList: [],
        minor_business_type: '',
        major_business_type: '',
        transaction_type: '',
        business_name: '',
        land_size: '',
        type: '',
      },
      indexFrom: '',
      indexTo: '',
      media: [],
      selected: [],
    }
  },
  computed: {
    getImage() {
      return this.selected
    },
    getClient() {
      return this.$store.state.clientInfo
    },
  },
  created() {
    this.getOptions('CM_1')
  },
  methods: {
    selectImgaeFromMedia(list) {
      // this.data.imageList = list
      this.selected = list
      // TODO: import 할때 신경쓰기
    },
    deleteImageFromMedia(single) {
      const target = this.selected.indexOf(single)
      this.selected.splice(target, 1)
    },
    updateData(data) {
      this.data = data
    },
    updateMediaData(data) {
      this.media = data
    },
    submit() {
      if (this.selected.length > 0) {
        this.data.photos = []
        this.data.imageList = []
        this.selected.forEach(img => {
          if (img.file_path) {
            this.data.photos.push(img.file_path)
            this.data.imageList.push(img.file_path)
          } else if (img.location) {
            this.data.photos.push(img.location)
            this.data.imageList.push(img.file_path)
          }
        })
      }

      if (this.media.length > 0) {
        this.data.embed = this.media
      } else {
        this.data.embed = []
      }

      this.data.land_size = this.data.land_size.toString()
      this.data.asking_price = this.data.asking_price.toString()
      if (this.data.sold_price) {
        this.data.sold_price = this.data.sold_price.toString()
      }
      this.data.status = this.data.status.toUpperCase()

      if (this.getClient.memberType !== 'superuser') {
        this.$store.dispatch('listings/addCommercialListing', this.data).then(response => {
          if (response.code === 200) {
            window.location.href = '/commercial'
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      } else if (this.getClient.memberType === 'superuser') {
        this.data.class = 'Commercial'
        console.log(this.data)
        const superData = { account_id: this.$route.params.account, options: this.data }

        this.$store.dispatch('superadmin/addSingleListing', superData).then(response => {
          console.log(response)
          if (response.code === 200) {
            window.location.href = '/accounts/' + this.$route.params.account
          } else {
            this.showToast('Failedsss', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.mlsState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.mlsState = null
    },
    handleOk(bvModalEvt) {
      this.handleSubmit(bvModalEvt)
    },
    handleSubmit(bvModalEvt) {
      if (!this.checkFormValidity()) {
        bvModalEvt.preventDefault()
        // eslint-disable-next-line no-useless-return
        return
      }

      this.$store.dispatch('listings/importCommercialMls', this.data.mls_id).then(response => {
        if (response.code === 200) {
          this.showToast('Load a listing', 'CheckCircleIcon', 'Listing has been loaded successfully', 'success', 'bottom-right')
          if (response.data.class === 'CM_1') {
            // this.data = response.data
            this.data = {
              status: response.data.status,
              property_type: 'Commercial',
              mls_id: response.data.mls_id ? response.data.mls_id : '',
              list_date: response.data.list_date ? response.data.list_date : '',
              asking_price: response.data.asking_price ? response.data.asking_price : '',
              sold_price: response.data.sold_price ? response.data.sold_price : '',
              class: response.data.class ? response.data.class : '',
              area: response.data.area ? response.data.area : '',
              subarea: response.data.subarea ? response.data.subarea : '',
              address_unit: response.data.address_unit ? response.data.address_unit : '',
              address_number: response.data.address_number ? response.data.address_number : '',
              address_street: response.data.address_street ? response.data.address_street : '',
              address_street_designation_id: response.data.address_street_designation_id ? response.data.address_street_designation_id : '',
              address_direction: response.data.address_direction ? response.data.address_direction : '',
              city: response.data.city ? response.data.city : '',
              province: response.data.province ? response.data.province : '',
              postal_code: response.data.postal_code ? response.data.postal_code : '',
              remark: response.data.remark ? response.data.remark : '',
              virtual_tour: response.data.virtual_tour ? response.data.virtual_tour : '',
              imageList: [],
              minor_business_type: response.data.minor_business_type ? response.data.minor_business_type : '',
              major_business_type: response.data.major_business_type ? response.data.major_business_type : '',
              transaction_type: response.data.transaction_type ? response.data.transaction_type : '',
              business_name: response.data.business_name ? response.data.business_name : '',
              land_size: response.data.land_size ? response.data.land_size : '',
              type: response.data.type ? response.data.type : '',
            }
            if (this.data.photos) {
              this.preloadPhotos()
            }
          }
        } else if (response.code === 404) {
          this.showToast('Failed', 'AlertCircleIcon', response.message, 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    getOptions(className) {
      const data = {
        options: Object.keys(this.data),
        class: className,
      }

      this.$store.dispatch('listings/getOptionsList', data).then(response => {
        if (response.code === 200) {
          this.options = response.data
        }
      }).catch(err => {
        console.error(err)
      })
    },
    filesAdded(file) {
      file.forEach(data => {
        if (data.size < 1024 * 500) {
          this.data.imageList.push(data)
        } else {
          this.$refs.myVueDropzone.removeFile(data)

          this.showToast('Upload Failed', 'AlertCircleIcon', data.name + ' exceeds the maximum file size (500KB)', 'danger', 'bottom-right')
        }
      })
    },
    fileDeleted(file) {
      this.data.imageList = this.data.imageList.filter(data => data.upload.uuid !== null && data.upload.uuid !== file.upload.uuid)
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
.vs__open-indicator{
  display:none !important;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
